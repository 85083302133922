import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import VanillaTilt from 'vanilla-tilt';
import { X, Filter, ChevronLeft, ChevronRight, ZoomIn } from 'lucide-react';

// Dummy imports (replace with your actual assets)
import GalleryLabel from './general-assets/hotdoggerbanner.png';

// Custom hook for intersection observer (reveal animations)
const useIntersectionObserver = (options = {}) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);
    
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};


// Tilt effect for gallery cards
const TiltCard = ({ children }) => {
  const tiltRef = useRef(null);
  
  useEffect(() => {
    if (tiltRef.current) {
      VanillaTilt.init(tiltRef.current, {
        max: 10,
        scale: 1.03,
        speed: 400,
        glare: true,
        "max-glare": 0.2,
        gyroscope: false
      });
    }
    
    return () => {
      if (tiltRef.current && tiltRef.current.vanillaTilt) {
        tiltRef.current.vanillaTilt.destroy();
      }
    };
  }, []);
  
  return <div ref={tiltRef}>{children}</div>;
};

// Enhanced gallery card component with animations
const GalleryCard = ({ album, onClick, index, delay }) => {
  const [cardRef, isVisible] = useIntersectionObserver({
    threshold: 0.2,
    rootMargin: '0px 0px -100px 0px'
  });
  
  return (
    <motion.div 
      ref={cardRef}
      initial={{ opacity: 0, y: 50 }}
      animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ 
        duration: 0.6, 
        delay: delay * 0.15,
        ease: [0.22, 1, 0.36, 1] 
      }}
      className="min-w-[280px] md:min-w-[340px] max-w-[400px] px-3 flex-shrink-0"
    >
      <TiltCard>
        <div className="relative aspect-[4/3] overflow-hidden group transition-all duration-300 shadow-lg hover:shadow-2xl rounded-lg">
          {/* Lazy loaded image */}
          <div className="w-full h-full bg-gray-200 animate-pulse absolute inset-0" />
          <img 
            src={album.image} 
            alt={`${album.title} gallery`} 
            loading="lazy"
            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110 relative z-10"
            onLoad={(e) => {
              e.target.previousSibling.classList.remove('animate-pulse');
              e.target.previousSibling.classList.add('opacity-0');
            }}
          />
          
          {/* Dark overlay that fades in on hover */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent opacity-40 group-hover:opacity-80 transition-opacity duration-500 z-20" />
          
          {/* View details button - modern & interactive */}
          <motion.div 
            className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-30"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <button
              onClick={() => onClick(album.id)}
              className="bg-white bg-opacity-90 hover:bg-opacity-100 rounded-full h-20 w-20 flex items-center justify-center group transition-all duration-300 shadow-md"
            >
              <div className="text-center">
                <div className="text-xs font-medium text-gray-700 uppercase">View</div>
                <div className="text-xs font-medium text-gray-700 uppercase">Details</div>
              </div>
            </button>
          </motion.div>
          
          {/* Album number label - more stylized */}
          <div className="absolute bottom-0 left-0 right-0 py-4 px-5 text-white z-30 pointer-events-none">
            <div className="flex items-center">
              <span className="text-xs font-bold tracking-wider bg-amber-600 text-white rounded px-2 py-1 inline-block">{`0${index + 1}`}</span>
              <span className="text-lg font-bold ml-2 tracking-wide">{album.title}</span>
            </div>
          </div>
        </div>
      </TiltCard>
      
      {/* Album title below image - better spacing and style */}
      <div className="mt-5 mb-8">
        <h3 className="text-xl font-semibold text-gray-800">{album.description}</h3>
      </div>
    </motion.div>
  );
};


const GalleryExhibition = ({ onClick }) => {
    const galleryRef = useRef(null);
    const [headerRef, headerVisible] = useIntersectionObserver({ threshold: 0.5 });
    
    // Album data with year, image, and description - sorted with newest first
    const albums = [
      {
        id: 2024,
        title: '2024',
        image: 'https://hotdogger.azureedge.net/public-assets/2024-Assets/LBM00794.jpg',
        description: 'Bigger, Better, Bratwurstier'
      },
      {
        id: 2023,
        title: '2023',
        image: 'https://hotdogger.azureedge.net/public-assets/2023-Assets/IMG_7436.CR2.jpg',
        description: 'Hotdogger 3, the rise of the meat sweats'
      },
      {
        id: 2022,
        title: '2022',
        image: 'https://hotdogger.azureedge.net/public-assets/2022-Assets/9CEB7EE9-A864-45B8-8854-6DF2B093F35C.jpeg',
        description: 'Double your pleasure, double your fun'
      },
      {
        id: 2021,
        title: '2021',
        image: 'https://hotdogger.azureedge.net/public-assets/2021-Assets/IMG_6834.jpg',
        description: 'The Inaugural Year'
      }
    ];
    
    // Use all albums without filtering
    const filteredAlbums = albums;
    
    // Scroll buttons handlers
    const scroll = (direction) => {
      if (galleryRef.current) {
        const scrollAmount = direction === 'left' ? -400 : 400;
        galleryRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    };
    
    return (
      <div className="relative mb-6">
        {/* Exhibition Title with Logo - Combined header */}
        <motion.div 
          ref={headerRef}
          initial={{ opacity: 0, y: 30 }}
          animate={headerVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          className="mb-12 px-6 md:px-12"
        >
          {/* Logo and heading in a flex container for desktop */}
          <div className="flex flex-col md:flex-row items-start md:items-center gap-6 justify-between mt-14">
            {/* Exhibition headings */}
            <div>
              <h2 className="text-6xl md:text-7xl font-extrabold tracking-tight text-gray-900 uppercase leading-none text-hotdoggerbrown">
                
                  Explore
              </h2>
              <h2 className="text-6xl md:text-8xl font-black tracking-tight text-gray-900 uppercase leading-none mt-2 text-hotdoggerbrown">
                
                  Galleries
              </h2>
            </div>
            
            {/* Banner image - hidden on mobile, shown on desktop */}
            <div className="hidden md:block md:w-1/2 lg:w-1/3 md:text-right">
              <img 
                src={GalleryLabel} 
                className="w-full inline-block ml-auto" 
                alt="The Hotdogger" 
              />
            </div>
          </div>
        </motion.div>
        
        {/* Gallery Container - unchanged */}
        <div 
          ref={galleryRef} 
          className="flex overflow-x-auto pb-10 hide-scrollbar pl-6 md:pl-12"
          style={{ scrollBehavior: 'smooth', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        >
          {filteredAlbums.length === 0 ? (
            <div className="py-20 text-center w-full">
              <p className="text-gray-500 text-lg">No galleries match your filter.</p>
            </div>
          ) : (
            <>
              {filteredAlbums.map((album, index) => (
                <GalleryCard 
                  key={album.id} 
                  album={album} 
                  onClick={onClick} 
                  index={index} 
                  delay={index}
                />
              ))}
            </>
          )}
          
          {/* Add minimal end padding */}
          <div className="min-w-[4px] flex-shrink-0"></div>
        </div>
        
        {/* Navigation Controls - unchanged */}
        <div className="flex justify-end mt-6 px-6 md:px-12">
          <div className="flex space-x-3">
            <motion.button 
              onClick={() => scroll('left')} 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="w-12 h-12 flex items-center justify-center bg-amber-700 text-white rounded-full hover:bg-amber-800 transition-colors duration-300 shadow-md"
              aria-label="Previous"
            >
              <ChevronLeft className="h-5 w-5" />
            </motion.button>
            <motion.button 
              onClick={() => scroll('right')}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="w-12 h-12 flex items-center justify-center bg-amber-700 text-white rounded-full hover:bg-amber-800 transition-colors duration-300 shadow-md"
              aria-label="Next"
            >
              <ChevronRight className="h-5 w-5" />
            </motion.button>
          </div>
        </div>
      </div>
    );
  };

// Main photo gallery component
const EnhancedPhotoGallery = () => {
  const navigate = useNavigate();
  
  const handleGalleryClick = (id) => {
    if (id === 'all') {
      // Navigate to a page showing all galleries
      navigate(`./all`);
    } else {
      // Navigate to specific year gallery
      navigate(`./${id}`);
    }
  };
  
  return (
    <section className="bg-cream flex-grow overflow-hidden">
      {/* CSS styles for the enhanced page */}
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@400;500;600;700;900&display=swap');
        
        :root {
          --color-accent: #B45309;
          --color-background: #FEF3C7;
        }
        
        body {
          font-family: 'Inter', sans-serif;
        }
        
        h1, h2, h3, h4 {
          font-family: 'Bebas Neue', sans-serif;
        }
        
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        
        .bg-cream {
          background-color: #F9EAB5;
        }
      `}</style>
      
      <div className="max-w-screen-xl mx-auto">

        
        {/* Enhanced Gallery Exhibition */}
        <GalleryExhibition onClick={(id) => handleGalleryClick(id)} />
        
        <div className="pb-12 md:pb-16"></div>
      </div>
    </section>
  );
};

export default EnhancedPhotoGallery;