import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AlbumBrowser = ({ onBack, albumId, albumDescription }) => {
    const { year } = useParams();
    const [images, setImages] = useState(null);
    const [loading, setLoading] = useState(true);
    
    // State for modal/lightbox
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Azure CDN base URL for displaying images
    const cdnBaseUrl = "https://hotdogger.azureedge.net/public-assets";
    
    // Azure Blob Storage URL for API operations (listing blobs)
    const blobApiUrl = "https://hotdogger.blob.core.windows.net/public-assets";

    useEffect(() => {
        setLoading(true);
        
        const fetchImagesFromAzure = async () => {
            try {
                // Define the folder path based on albumId
                const folderPath = `${albumId}-Assets`;
                
                // Fetch the list of images from the folder
                const imageList = await fetchImageList(folderPath);
                
                setImages(imageList);
            } catch (error) {
                console.error('Error loading images from Azure:', error);
                setImages([]);
            } finally {
                setLoading(false);
            }
        };

        fetchImagesFromAzure();
    }, [albumId]);

    // Function to fetch images from Azure Storage
    const fetchImageList = async (folderPath) => {
        try {
            // IMPORTANT: Keep using the blob storage URL for the API call
            // CDN doesn't support the list blobs operation
            const response = await fetch(`${blobApiUrl}?restype=container&comp=list&prefix=${folderPath}/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/xml',
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch image list: ${response.status}`);
            }

            const xmlText = await response.text();
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlText, "text/xml");
            
            const blobs = xmlDoc.getElementsByTagName("Blob");
            
            // Transform the XML response into our image format
            return Array.from(blobs).map((blob, index) => {
                const name = blob.getElementsByTagName("Name")[0].textContent;
                // Filter out any non-image files
                if (!/\.(jpg|jpeg|png|svg)$/i.test(name)) return null;
                
                // Extract the filename from the path
                const filename = name.split('/').pop().replace(/\.(png|jpe?g|svg)$/, '');
                
                // Get the file size if available
                const sizeElement = blob.getElementsByTagName("Content-Length");
                const actualSize = sizeElement.length > 0 ? parseInt(sizeElement[0].textContent) : 0;
                const sizeMB = actualSize > 0 ? (actualSize / (1024 * 1024)).toFixed(1) : "1.0";
                
                return {
                    // Use the CDN URL for image display
                    src: `${cdnBaseUrl}/${name}`,
                    title: filename,
                    size: `${sizeMB}MB`,
                    index: index,
                    alt: `${filename} - ${albumId} album`
                };
            }).filter(Boolean); // Remove non-image files
        } catch (error) {
            console.error("Error fetching from Azure Storage:", error);
            return []; // Return empty array if there's an error
        }
    };

    // Handle image click to open modal
    const openImageModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
        // Prevent scrolling when modal is open
        document.body.style.overflow = 'hidden';
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
        // Re-enable scrolling
        document.body.style.overflow = 'auto';
    };

    // Navigate through images in modal
    const navigateImage = (direction) => {
        if (!images || images.length === 0) return;
        
        const currentIndex = selectedImage.index;
        let newIndex;
        
        if (direction === 'next') {
            newIndex = (currentIndex + 1) % images.length;
        } else {
            newIndex = (currentIndex - 1 + images.length) % images.length;
        }
        
        setSelectedImage(images[newIndex]);
    };

    // Handle keyboard navigation in modal
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!isModalOpen) return;
            
            if (e.key === 'Escape') {
                closeModal();
            } else if (e.key === 'ArrowRight') {
                navigateImage('next');
            } else if (e.key === 'ArrowLeft') {
                navigateImage('prev');
            }
        };
        
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isModalOpen, selectedImage]);

    return (
        <div className="bg-cream min-h-screen">
            <div className="px-6 md:px-20 lg:max-w-screen-xl mx-auto">
                <header>
                    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <div className="text-center sm:text-left">
                                <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                                    You're viewing the {albumId} album!
                                </h1>

                                <p className="mt-1.5 text-sm text-gray-500">
                                    {albumDescription}
                                </p>
                            </div>

                            <div className="text-center mt-4 block flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
                                <a
                                    className="group relative inline-block focus:outline-none focus:ring"
                                    href=""
                                    onClick={(event) => { event.preventDefault(); onBack() }}
                                >
                                    <span
                                        className="absolute inset-0 translate-x-0 translate-y-0 bg-babyblue transition-transform group-hover:translate-y-1.5 group-hover:translate-x-1.5"
                                    ></span>

                                    <span
                                        className="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest"
                                    >
                                        Back
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Loading state */}
                {loading && (
                    <div className="flex justify-center items-center py-16">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
                    </div>
                )}

                {/* Image Gallery */}
                {!loading && (
                    <div className="pb-12">
                        {albumId && images && images.length > 0 ? (
                            <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-8">
                                {images.map((image) => (
                                    <li key={image.index} className="relative">
                                        <div className="group overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                                            <img
                                                alt={image.alt}
                                                src={image.src}
                                                loading="lazy"
                                                width="400"
                                                height="280"
                                                className="pointer-events-none aspect-[10/7] object-cover group-hover:opacity-75 w-full h-auto"
                                            />
                                            <button 
                                                type="button" 
                                                className="absolute inset-0 focus:outline-none"
                                                onClick={() => openImageModal(image)}
                                                aria-label={`View ${image.title}`}
                                            >
                                                <span className="sr-only">View details for {image.title}</span>
                                            </button>
                                        </div>
                                        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                                            {image.title}
                                        </p>
                                        <p className="pointer-events-none block text-sm font-medium text-gray-500">
                                            {image.size}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="text-center py-12">
                                <p className="text-gray-500">No images found for this album.</p>
                            </div>
                        )}
                    </div>
                )}

                {/* Image Modal/Lightbox */}
                {isModalOpen && selectedImage && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90 p-4">
                        {/* Close button */}
                        <button 
                            className="absolute top-4 right-4 text-white hover:text-gray-300 focus:outline-none z-50"
                            onClick={closeModal}
                            aria-label="Close modal"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        
                        {/* Previous button */}
                        <button 
                            className="absolute left-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300 focus:outline-none"
                            onClick={() => navigateImage('prev')}
                            aria-label="Previous image"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                        
                        {/* Next button */}
                        <button 
                            className="absolute right-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300 focus:outline-none"
                            onClick={() => navigateImage('next')}
                            aria-label="Next image"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                        
                        {/* Image container */}
                        <div className="max-w-5xl max-h-[90vh] overflow-auto">
                            <img
                                src={selectedImage.src}
                                alt={selectedImage.alt}
                                className="max-w-full max-h-[85vh] object-contain mx-auto"
                            />
                            <div className="text-center mt-4 text-white">
                                <h2 className="text-xl font-medium">{selectedImage.title}</h2>
                                <p className="text-sm text-gray-300">{selectedImage.size}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AlbumBrowser;