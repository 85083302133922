import React from 'react';
import {
  Route,
  Routes,
  Navigate,
  useSearchParams
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { ColorProvider } from './components/ColorContext'; 
import PageNav from './components/PageNav';
import StatusBarManager from './components/StatusBarManager';
import About from './components/About/About';
import Home from './components/Home/Home';
import PhotoGalleryRoutes from './components/PhotoGallery/PhotoGalleryRoutes';
import Splash from './components/Registration/Splash';
import Footer from './components/Footer';


function App() {
  return (
    <ColorProvider> 
      <div className='flex flex-col min-h-screen'>
        <StatusBarManager />
        <PageNav />
        <Routes>
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery/*" element={<PhotoGalleryRoutes />} />
          <Route path="/Register" element={<Splash />} />
          <Route path="/" element={<Navigate to="/Home" replace />} />
          <Route path="*" element={<Navigate to="/Home" replace />} />
        </Routes>
        <Footer />
        <Toaster />
      </div>
    </ColorProvider>
  );
}

export default App;