const faq = [
    {
        "question": "Where is the event, when does it start, and how long is it?",
        "answer": "The Hotdogger runs from sunrise to sunset on October 11th, 2025, and is located at 18 Road in Fruita CO. 39°18'37.9N 108°42'13.1W"
    },
    {
        "question": "How does my team get points?",
        "answer" : "Each team's score will be the sum of their laps biked (each with different point totals) and hotdogs eaten. See the course overview for lap point details. You must eat a bun with your hotdog for the points to count."
    },
    {
        "question": "What kind of bike should I ride?",
    "answer": "We recommend a hardtail or full suspension mountain bike. However, we have had participants ride PBR on their gravel bikes (and a unicycle). You MAY NOT ride an e-bike!"
    },
    {
        "question": "What if I don't have a bike suited for this terrain? ",
        "answer": "You can rent bikes in Fruita from Over The Edge Sports! If you're not into biking, we would love to have you come and earn points for your team by eating hotdogs and having a good time!"
    },
    {
        "question": "What if I don't eat hotdogs? or glutenous buns? ",
        "answer": "We will have some meat-substitute hotdogs and GF buns for those who need them. If you are planning to eat a significant quantity of either you may want to bring some extras."
    },
    {
        "question": "Where will my team camp?",
        "answer": "We will send out your team's campsite before the event. You are responsible for bringing all of your own gear to camp, as well as any sustenance other than the hotdogs you plan to consume on race day. Your team will park at your assigned campsite."
    },
    {
        "question": "Where will I be able to park my car?",
        "answer": "If you are camping you and your teammates will be able to park your cars at your campsite. If you are not planning on camping then there will be ample day parking at dog plaza. "
    },
    {
        "question": "What should I wear? ",
        "answer": "Team costumes are encouraged, if you need some ideas check out our friends at Party Shirt International! Please make sure you bring proper clothing for a couple days/nights out in the Western Slope desert."
    },
    {
        "question": "How many people can be on my team?",
        "answer": "Teams may consist of between 1 and 6 people"
    },
    {
        "question": "How do we keep track of our team's score throughout the day? ",
        "answer": "There will be a large scoreboard in Dog Plaza where everyone will be recording their scores. You must return to Dog Plaza to record your points after each lap. "
    },
    {
        "question": "How are hotdogs being cooked?",
        "answer": "Your admins have secured a couple cooks who have offered to make dogs all day for participants, please be nice to them!!"
    },
    {
        "question": "I can no longer attend The Hotdogger. Can my registration be refunded?",
        "answer": "No. Due to logistical complexity we will not be offering refunds. You may transfer your registration to another participant. See the FAQ below for more details."
    },
    {
        "question": "I or one of my team members can no longer participate. Can I replace them with someone new?",
        "answer": "Yes. BikeReg offers a feature to transfer your registration to a new participant. Any transfer of registration fees is between you and your replacement (e.g. venmo)."
    },
    {
        "question": "Can I volunteer for The Hotdogger?",
        "answer": "Yes we'd love to have you volunteer at The Hotdogger! Please note that registering as a volunteer is separate from registering as a race participant and does not guarantee a camping spot. Volunteering will consist of cooking hotdogs, manning the merch/donation tent, helping us set up the event, and/or whatever else we may need that you feel comfortable and capable of assisting with. If you'd like to volunteer please sign up on Bike Reg (bikereg.com/thehotdogger).  If you have any additional questions about volunteering please reach out to us at hotdogger.mtb@gmail.com."
    }
]

export default faq;