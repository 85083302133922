'use client'
import { useState, useEffect } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { CalendarIcon, UserGroupIcon, CurrencyDollarIcon, MapPinIcon } from '@heroicons/react/24/solid'

const navigation = [
 { name: 'Product', href: '#' },
 { name: 'Features', href: '#' },
 { name: 'Marketplace', href: '#' },
 { name: 'Company', href: '#' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  // Countdown timer logic
  useEffect(() => {
    const targetDate = new Date('June 9, 2025 17:00:00 MDT').getTime()
    
    const updateCountdown = () => {
      const now = new Date().getTime()
      const difference = targetDate - now
      
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000)
        })
      }
    }
    
    updateCountdown()
    const timer = setInterval(updateCountdown, 1000)
    
    return () => clearInterval(timer)
  }, [])

  return (
    <div className="flex-1 flex flex-col">
      {/* Hero section with animated background */}
      <div className="flex-grow relative isolate overflow-hidden bg-white pt-2">
        {/* Decorative elements */}
        <div className="absolute inset-0 z-0 opacity-10">
          <div className="absolute top-0 left-0 w-full h-full bg-repeat" 
               style={{ backgroundImage: "url('data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E')" }}
          ></div>
        </div>
  
        <div className="mx-auto max-w-7xl px-6 py-10 sm:py-16 lg:px-8">
          {/* Preserving the original responsive grid structure */}
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            {/* Main heading with animation */}
            <h1 className="max-w-2xl text-balance text-5xl font-bold tracking-tight text-dark sm:text-7xl lg:col-span-2 xl:col-auto animate-fadeIn relative">
              <span className="inline-block transform hover:scale-105 transition-transform duration-500">
                Registration Opens June 9th, 2025
              </span>
              <div className="absolute -top-6 -right-6 rotate-12 bg-yellow-400 text-red-600 text-sm px-4 py-1 rounded-full font-extrabold shadow-lg animate-pulse">
                Mark your calendar!
              </div>
            </h1>
  
            {/* Content card with glass effect */}
            <div className="mt-6 max-w-xl lg:mt-8 xl:col-end-1 xl:row-start-1">
              <div className="bg-white bg-opacity-10 backdrop-blur-md p-6 rounded-xl shadow-xl border border-white border-opacity-20">
                <p className="text-pretty text-lg font-medium text-dark sm:text-xl/8">
                  The Hotdogger is moving to team-based registration for 2025! Teams of 1-6 participants can register for $750 per team. Registration includes camping, unlimited hotdogs, swag, and a donation to COPMOBA.
                </p>
                
                {/* Feature highlights */}
                <div className="mt-6 grid grid-cols-2 gap-4">
                  <div className="flex items-center gap-2 text-dark">
                    <UserGroupIcon className="h-5 w-5 text-yellow-300" />
                    <span>Teams of 1-6 people</span>
                  </div>
                  <div className="flex items-center gap-2 text-dark">
                    <CurrencyDollarIcon className="h-5 w-5 text-yellow-300" />
                    <span>$750 per team</span>
                  </div>
                  <div className="flex items-center gap-2 text-dark">
                    <CalendarIcon className="h-5 w-5 text-yellow-300" />
                    <span>5:00 PM MT, June 9th</span>
                  </div>
                  <div className="flex items-center gap-2 text-dark">
                    <MapPinIcon className="h-5 w-5 text-yellow-300" />
                    <span>Includes camping</span>
                  </div>
                </div>
  
                <p className="mt-4 text-pretty text-base text-dark">
                  Space will be limited this year, so gather your crew and prepare your team information in advance.
                </p>
  
                <div className="mt-8 flex flex-col sm:flex-row items-center gap-y-4 gap-x-6">
                  <a
                    href="https://www.bikereg.com/thehotdogger"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative overflow-hidden group rounded-full bg-yellow-400 px-5 py-3 text-md font-bold text-red-600 shadow-lg hover:bg-yellow-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500 transition-all duration-300 w-full sm:w-auto text-center"
                  >
                    <span className="relative z-10">Register for The Hotdogger 2025</span>
                    <span className="absolute top-0 left-0 w-full h-0 bg-white bg-opacity-30 transition-all duration-300 group-hover:h-full"></span>
                  </a>
                  <a href="https://docs.google.com/document/d/1x8K8VlQdNOjIT8QrbY4-oKkJLaEtDONam2GmZwEhWXs/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-md font-bold text-hotdoggerbrown hover:text-light transition-colors duration-300 flex items-center"
                  >
                    View Registration FAQ <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-1">→</span>
                  </a>
                </div>
              </div>
            </div>
  
            {/* Image with enhanced styling */}
            <div className="relative mt-10 aspect-[6/5] w-full max-w-lg sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-8 group">
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-yellow-500 to-red-600 transform rotate-3 scale-105 opacity-30 group-hover:opacity-40 transition-all duration-500"></div>
              <img
                alt="The Hotdogger Group Photo"
                src='https://hotdogger.azureedge.net/public-assets/2024-Assets/LBM00794.jpg'
                className="relative rounded-2xl object-cover w-full h-full shadow-2xl transform transition-transform duration-500 group-hover:scale-[1.02] z-10"
              />
              <div className="absolute -bottom-4 -right-4 bg-yellow-400 text-red-600 py-2 px-4 rounded-lg shadow-lg font-bold transform rotate-6 z-20">
                Join us in 2025!
              </div>
            </div>
          </div>
          
          {/* Countdown timer - FIXED RESPONSIVE VERSION */}
          <div className="mt-8 flex justify-start space-x-4 max-w-md">
            <div className="bg-white bg-opacity-20 backdrop-blur-sm rounded-lg shadow-xl p-2 w-16 sm:w-20">
              <div className="h-10 flex items-center justify-center">
                <span className="text-2xl font-bold text-dark">{timeLeft.days}</span>
              </div>
              <div className="text-center">
                <span className="text-xs font-medium text-dark uppercase">Days</span>
              </div>
            </div>
            <div className="bg-white bg-opacity-20 backdrop-blur-sm rounded-lg shadow-xl p-2 w-16 sm:w-20">
              <div className="h-10 flex items-center justify-center">
                <span className="text-2xl font-bold text-dark">{timeLeft.hours}</span>
              </div>
              <div className="text-center">
                <span className="text-xs font-medium text-dark uppercase">Hours</span>
              </div>
            </div>
            <div className="bg-white bg-opacity-20 backdrop-blur-sm rounded-lg shadow-xl p-2 w-16 sm:w-20">
              <div className="h-10 flex items-center justify-center">
                <span className="text-2xl font-bold text-dark">{timeLeft.minutes}</span>
              </div>
              <div className="text-center">
                <span className="text-xs font-medium text-dark uppercase">Mins</span>
              </div>
            </div>
            <div className="bg-white bg-opacity-20 backdrop-blur-sm rounded-lg shadow-xl p-2 w-16 sm:w-20">
              <div className="h-10 flex items-center justify-center">
                <span className="text-2xl font-bold text-dark">{timeLeft.seconds}</span>
              </div>
              <div className="text-center">
                <span className="text-xs font-medium text-dark uppercase">Secs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}