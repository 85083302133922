import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Create a context for our color scheme
const ColorContext = createContext({
  backgroundColor: '#B5E3F9', // Default color (Home)
});

// Provider component that determines and provides the current color
// Helper function to determine color based on path
const getColorForPath = (pathname) => {
  if (pathname.toLowerCase().includes('about')) {
    return '#E4788E';
  } else if (pathname.toLowerCase().includes('gallery')) {
    return '#F9EAB5';
  } else if (pathname.toLowerCase().includes('register')) {
    return 'white';
  }
  return '#B5E3F9'; // Default (Home)
};

export const ColorProvider = ({ children }) => {
  const location = useLocation();
  // Initialize with the correct color immediately
  const [backgroundColor, setBackgroundColor] = useState(() => {
    return getColorForPath(window.location.pathname);
  });

  useEffect(() => {
    // Determine color based on current path
    const newColor = getColorForPath(location.pathname);
    
    // Only update if color actually changed
    if (newColor !== backgroundColor) {
      setBackgroundColor(newColor);
      
      // Update the body background color
      document.body.style.backgroundColor = newColor;
    }
  }, [location, backgroundColor]);

  return (
    <ColorContext.Provider value={{ backgroundColor }}>
      {children}
    </ColorContext.Provider>
  );
};

// Custom hook to easily access the color context
export const useColorContext = () => useContext(ColorContext);

export default ColorContext;