import React, { useMemo } from 'react';
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useColorContext } from './ColorContext'; // Import our color context hook

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'About', href: '/About', current: false },
  { name: 'Register', href: '/Register', current: false },
  { name: 'Gallery', href: '/Gallery', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PageNav() {
  // Use the color context instead of determining colors directly
  const { backgroundColor } = useColorContext();
  
  // Create a memoized style object to prevent unnecessary re-renders
  const navStyle = useMemo(() => ({ 
    backgroundColor,
    transition: 'background-color 0.3s ease'
  }), [backgroundColor]);

  return (
    <>
      <Disclosure as="nav" style={navStyle}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-hotdoggerbrown hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-end sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-left ">
                    <a
                      className="block h-10 w-auto"
                      alt="The Hotdogger"
                      href='./'
                    ><p className='text-4xl'>🌭</p></a>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          target={''}
                          href={item.href}
                          className={classNames(
                            'text-hotdoggerbrown hover:bg-hotdoggerbrown hover:text-white px-3 py-2 rounded-md text-md font-light tracking-wide' 
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    target={item.name == 'Reg'}
                    as="a"
                    href={item.href}
                    className={classNames(
                      'text-hotdoggerbrown hover:text-dijon',
                      'block px-3 py-2 rounded-md text-base font-light tracking-wide'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}