import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import React, { useState,useEffect } from 'react'
import PhotoGallery from './PhotoGallery';
import AlbumBrowser from './AlbumBrowser';

const YearGallery = () => {
    const {year} = useParams();

    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/Gallery`);
      }
    

    const albumDescriptions = {
        2021: 'The inagural Hotdogger was small but mighty. A dream come to life, sweaty, meaty, and chock full of good times!',
        2022: `The 2022 Dogger' was epic! Check out the photos below to see Hotdogger participants getting the meat sweats!`,
        2023: 'The 2023 Hotdogger was bigger and better than ever. Hundreds of folks from across the country came to raise money for COPMOBA with their mouths (and feet).',
        2024: 'The 2024 Hotdogger was a blast! Check out how much fun the Hotdogger participants had!'
    }

    console.log('truthiness is ' + year in albumDescriptions)

    return(
        <>
        {year in albumDescriptions ? <AlbumBrowser albumDescription={albumDescriptions[year]} albumId={year} onBack={(e) => {handleClick();}}/> : navigate('/Gallery') }
        </>
    )
}

function PhotoGalleryRoutes() {

    return (
        <Routes>
            <Route path="/" element={<PhotoGallery />} />
            <Route
                path="/:year"
                element={<YearGallery />}
            />
        </Routes>
    )
}

export default PhotoGalleryRoutes;