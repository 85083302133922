import React, { useEffect } from 'react';
import { useColorContext } from './ColorContext'; // Import our color context hook

const StatusBarManager = () => {
  // Use the color context instead of determining colors directly
  const { backgroundColor } = useColorContext();
  
  useEffect(() => {
    // Set the meta tag for iOS status bar
    const metaStatusBar = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
    if (metaStatusBar) {
      metaStatusBar.setAttribute('content', 'black-translucent');
    } else {
      const meta = document.createElement('meta');
      meta.name = 'apple-mobile-web-app-status-bar-style';
      meta.content = 'black-translucent';
      document.head.appendChild(meta);
    }
    
    // Set the meta tag for web app capable
    const metaCapable = document.querySelector('meta[name="apple-mobile-web-app-capable"]');
    if (!metaCapable) {
      const meta = document.createElement('meta');
      meta.name = 'apple-mobile-web-app-capable';
      meta.content = 'yes';
      document.head.appendChild(meta);
    }
    
    // Update viewport meta tag if needed
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      if (!viewportMeta.content.includes('viewport-fit=cover')) {
        viewportMeta.content = viewportMeta.content + ', viewport-fit=cover';
      }
    }
    
  }, []);
  
  return null; // This component doesn't render anything
};

export default StatusBarManager;